import React, { useContext } from "react";
import {
  StyledTextAndImage,
  StyledTextAndImageContent,
  StyledTextAndImageImage,
  StyledTextAndImageContentList,
  StyledTextAndImageContentListItem,
} from "./style";
import { StyledGridRow } from "../../commons/Grid";
import {
  StyledTitle,
  StyledBigText,
  StyledParagraph,
} from "../../commons/Typography";
import Button from "../Button/Button";
import Icon, { icons } from "../Icon/Icon";
import { Colors } from "../../commons/Theme";
import { DataContext } from "../../contexts/DataContext";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

export default ({
  title,
  bigText,
  subtitle,
  list,
  cta,
  image,
  theme,
  upside,
  imageProps = {},
  children,
  allImages,
}) => {
  const { i18nLink } = useContext(DataContext);
  const imageData = allImages?.find(e => e.path === image);
  return (
    <StyledGridRow noMargin>
      <StyledTextAndImage theme={theme} upside={upside}>
        <StyledTextAndImageContent minHeight={imageProps.height}>
          {title && <StyledTitle dangerouslySetInnerHTML={{ __html: title }} />}
          <StyledBigText dangerouslySetInnerHTML={{ __html: bigText }} />
          {subtitle && (
            <StyledParagraph dangerouslySetInnerHTML={{ __html: subtitle }} />
          )}
          {list && (
            <StyledTextAndImageContentList>
              {list.map((item, index) => (
                <StyledTextAndImageContentListItem
                  key={index}
                  dangerouslySetInnerHTML={{ __html: item }}
                />
              ))}
            </StyledTextAndImageContentList>
          )}
          {cta && (
            <Button as="div" theme="white" noPadding to={cta.url ? i18nLink(`${cta.url}`) : null}>
              {cta.label}
              <Icon icon={icons.arrowRight} color={Colors.black} />
            </Button>
          )}
          {children}
        </StyledTextAndImageContent>
        <div>
          <GatsbyImage
            style={{ "max-width": "500px" }}
            image={getImage(imageData)} alt={imageData.alt}
          />
        </div>
      </StyledTextAndImage>
    </StyledGridRow>
  );
};
